import { useState } from "react";
import { Rating } from "@mantine/core";

import FeedbackModal from "./FeedbackModal/FeedbackModal";

const EventRating = ({
  rating,
  setRating,
  readOnly,
  eventId,
  feedback,
  source,
  meetingTitle,
  currentTag,
  tagOptions,
  handleOnTagClick,
  hasAttended,
  onUpdateRating,
  onFeedBackUpdate,
}) => {

  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  function handleRatingChange(value) {
    setRating(value);
    onUpdateRating(value, eventId);
    if (value <= 3) {
      setFeedbackModalOpen(true);
    }
  }

  return (
    <>
      <Rating
        color={readOnly || hasAttended === false ? "gray" : "yellow"}
        value={rating}
        fractions={1}
        onChange={handleRatingChange}
        readOnly={readOnly || hasAttended === false}
      />
      {/* have to send stars as prop to feedback modal */}
      <FeedbackModal
        opened={feedbackModalOpen}
        setOpened={setFeedbackModalOpen}
        stars={rating}
        eventId={eventId}
        feedback={feedback}
        source={source}
        meetingTitle={meetingTitle}
        currentTag={currentTag}
        tagOptions={tagOptions}
        handleOnTagClick={handleOnTagClick}
        onFeedBackUpdate={onFeedBackUpdate}
      />
    </>
  );
};

export default EventRating;
